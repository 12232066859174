import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import about from 'modules/about';
import announcements from 'modules/announcements';
import authentication from 'modules/authentication';
import authenticationInterface from 'modules/authentication-interface';
import addressFields from 'modules/address-fields';
import session from 'modules/session';
import orderHistory from 'modules/order-history';
import cart from 'modules/cart';
import cartAddons from 'modules/cart-addons';
import cartWidget from 'modules/cart-widget';
import analytics from 'modules/analytics';
import authModal from 'modules/auth-modal';
import footer from 'modules/footer';
import ui from 'modules/ui';
import restockNotifications from 'modules/restock-notifications';
import order from 'modules/order';
import experiments from 'modules/experiments';
import addressBook from 'modules/address-book';
import redirects from 'modules/redirects';
import backInBrowser from 'modules/back-in-browser';
import account from 'modules/account';
import giftCards from 'modules/gift-cards';
import searchResults from 'modules/search-results';
import shippingTouchpoints from 'modules/shipping-touchpoints';
import sitemap from 'modules/sitemap';
import search from 'modules/search';
import userFavorites from 'modules/user-favorites';
import wishlist from 'modules/wishlist';
import shippingRules from 'modules/shipping-rules';
import smsModal from 'modules/sms-modal';
import callouts from 'modules/callouts';
import notifications from 'modules/notifications';
import navigation from 'modules/navigation';
import query from 'modules/query';
import yotpo from 'modules/yotpo';
import stylitics from 'modules/stylitics';
import bundles from 'modules/bundles';
import product from 'modules/product';

const defaultReducers = {
  about,
  announcements,
  authentication,
  authenticationInterface,
  session,
  countries: addressFields,
  orderHistory,
  cart,
  cartWidget,
  cartAddons,
  analytics,
  authModal,
  ui,
  restockNotifications,
  order,
  experiments,
  addressBook,
  redirects,
  backInBrowser,
  footer,
  account,
  giftCards,
  searchResults,
  shippingTouchpoints,
  sitemap,
  search,
  userFavorites,
  wishlist,
  shippingRules,
  smsModal,
  callouts,
  notifications,
  navigation,
  query,
  yotpo,
  stylitics,
  bundles,
  product
};

const defaultReducersList = Object.keys(defaultReducers);

const createReducer = (history, asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    ...defaultReducers,
    ...asyncReducers
  });
};

export { defaultReducersList };
export default createReducer;
