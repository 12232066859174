// @flow

import * as React from 'react';
import { DateTime } from 'luxon';

import type { AuthDomain } from 'modules/auth-modal/types';
import useCurrentDate from 'hooks/useCurrentDate';

const ALLOWED_AUTH_DOMAIN_OVERRIDE = ['email-only', 'promotion'];

const useSoftGate = (
  force: boolean,
  authDomain: AuthDomain | null,
  experiment?: AuthDomain | null
) => {
  const currentDate = useCurrentDate();

  const [modalDomain, setModalDomain] = React.useState<null | AuthDomain>(null);

  React.useEffect(() => {
    if (
      !ALLOWED_AUTH_DOMAIN_OVERRIDE.includes(experiment || authDomain) ||
      !authDomain
    ) {
      if (authDomain) {
        setModalDomain(experiment || authDomain);
      }

      return;
    }

    const sitewideStart = DateTime.utc(2024, 11, 13, 8, 0); // Adjusted for Nov 13 12:00 AM PST
    const sitewideEnd = DateTime.utc(2024, 11, 16, 8, 0); // Adjusted for Nov 16 12:00 AM PST

    const cyberWeekStart = DateTime.utc(2024, 11, 25, 8, 0); // Adjusted for Nov 25 12:00 AM PST
    const cyberWeekEnd = DateTime.utc(2024, 12, 4, 8, 0); // Adjusted for Dec 4 12:00 AM PST

    if (currentDate >= sitewideStart && currentDate < sitewideEnd) {
      setModalDomain('sitewide-sale');
    } else if (currentDate >= cyberWeekStart && currentDate < cyberWeekEnd) {
      setModalDomain('cyber-week');
    } else {
      setModalDomain(experiment || authDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authDomain, experiment]);

  return modalDomain;
};

export default useSoftGate;
