import { ProductStateType, RelatedProductType } from './types';
import { ProductTilePayloadType } from 'types/tiles';

const fetchProductDetails = (slug: string) => ({
  type: 'PRODUCT/FETCH_DETAILS',
  payload: { slug }
});

const fetchProductDetailsSuccess = (data: ProductStateType) => ({
  type: 'PRODUCT/FETCH_DETAILS_SUCCESS',
  payload: {
    data
  }
});

const fetchProductDetailsFailure = () => ({
  type: 'PRODUCT/FETCH_DETAILS_FAILURE'
});

const fetchReviewCount = (sku: string) => ({
  type: 'PRODUCT/FETCH_REVIEW_COUNT',
  payload: {
    sku
  }
});

const fetchReviewCountSuccess = (count: number) => ({
  type: 'PRODUCT/FETCH_REVIEW_COUNT_SUCCESS',
  payload: {
    count
  }
});

const fetchReviewCountFailure = () => ({
  type: 'PRODUCT/FETCH_REVIEW_COUNT_FAILURE'
});

const fetchRecommendations = (sku: string) => ({
  type: 'PRODUCT/FETCH_RECOMMENDATIONS',
  payload: {
    sku
  }
});

const fetchRecommendationsSuccess = (
  recommendations: ProductTilePayloadType[],
  experiment: {
    name: string;
    condition: string;
  }
) => ({
  type: 'PRODUCT/FETCH_RECOMMENDATIONS_SUCCESS',
  payload: {
    recommendations,
    experiment
  }
});

const addToRecentlyViewedNew = (sku: string) => ({
  type: 'PRODUCT/ADD_TO_RECENTLY_VIEWED_NEW',
  payload: {
    sku
  }
});

const addToRecentlyViewedNewSuccess = (products: ProductTilePayloadType[]) => ({
  type: 'PRODUCT/ADD_TO_RECENTLY_VIEWED_NEW_SUCCESS',
  payload: {
    products
  }
});

const addToRecentlyViewedNewFailure = () => ({
  type: 'PRODUCT/ADD_TO_RECENTLY_VIEWED_NEW_FAILURE'
});

const fetchRecentlyViewedProducts = (sku: string) => ({
  type: 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS',
  payload: {
    sku
  }
});

const fetchRecentlyViewedProductsSuccess = (
  products: ProductTilePayloadType[]
) => ({
  type: 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS_SUCCESS',
  payload: {
    products
  }
});

const fetchRecentlyViewedProductsFailure = () => ({
  type: 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS_FAILURE'
});

const setProductLoading = (loadingState: boolean) => ({
  type: 'PRODUCT/SET_LOADING_STATE',
  loadingState
});

const setSizeGuideOpen = (isOpen: boolean) => ({
  type: 'PRODUCT/SET_SIZE_GUIDE_OPEN',
  isOpen
});

const fetchRelatedProducts = (sku: string) => ({
  type: 'PRODUCT/FETCH_RELATED_PRODUCTS',
  payload: {
    sku
  }
});

const fetchRelatedProductsSuccess = (products: RelatedProductType[]) => ({
  type: 'PRODUCT/FETCH_RELATED_PRODUCTS_SUCCESS',
  payload: {
    products
  }
});

const fetchRelatedProductsFailure = () => ({
  type: 'PRODUCT/FETCH_RELATED_PRODUCTS_FAILURE'
});

export {
  fetchProductDetails,
  fetchProductDetailsSuccess,
  fetchProductDetailsFailure,
  fetchReviewCount,
  fetchReviewCountSuccess,
  fetchReviewCountFailure,
  fetchRecommendations,
  fetchRecommendationsSuccess,
  setProductLoading,
  setSizeGuideOpen,
  fetchRelatedProducts,
  fetchRelatedProductsSuccess,
  fetchRelatedProductsFailure,
  addToRecentlyViewedNew,
  addToRecentlyViewedNewSuccess,
  addToRecentlyViewedNewFailure,
  fetchRecentlyViewedProducts,
  fetchRecentlyViewedProductsSuccess,
  fetchRecentlyViewedProductsFailure
};
