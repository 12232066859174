import { ProductStateType, ProductActionType } from './types';

const initialState: ProductStateType = {
  name: '',
  sku: '',
  category: '',
  userLimit: 0,
  finalSale: false,
  deliveryOverrideText: '',
  earliestDeliveryDays: 0,
  latestDeliveryDays: 0,
  sizingNotes: '',
  sizeGuideContent: '',
  sizeGuideOpen: false,
  productGroupId: 0,
  path: '',
  url: '',
  images: [
    {
      url: '',
      alt: '',
      photoType: 'laydown',
      altMissing: false
    }
  ],
  imagesCaption: '',
  brand: {
    name: '',
    url: ''
  },
  breakoutTitle: '',
  breakoutContent: '',
  descriptionBlocks: [],
  relatedProducts: [],
  relatedProductsLoading: false,
  displayPrice: '',
  total: 0,
  displayShippingPrice: '',
  shippingPrice: 0,
  shippingCategory: '',
  shippingRestrictions: [],
  msrp: '',
  inStock: false,
  color: '',
  hoverColorName: '',
  colorLabel: '',
  colorHex: '',
  secondaryColorHex: '',
  sizeLabel: '',
  returnPolicy: '',
  returnPolicyText: '',
  returnPolicyId: 'direct',
  availability: '',
  featuredReviewQuote: null,
  featuredReviewAuthor: null,
  deals: [],
  variants: [],
  alternates: [],
  breadCrumbs: [
    {
      title: '',
      url: ''
    }
  ],
  aggregateRating: { reviewCount: 0, ratingValue: 0 },
  video: null,
  loadingProduct: true,
  noProductFound: false,
  liveAt: null,
  dontMiss: false,
  editorialName: null,
  sizeGuideImages: [],
  availableOn: null,
  deletedAt: null,
  customShipDate: null,
  hasPurchased: null,
  masterVariantId: 0,
  productPageBlocks: [],
  showSoldOutPrompt: false,
  productGroupLinks: [],
  promotionable: true,
  clearance: false,
  recentlyViewedProductsNew: [],
  productGroupCalloutImage: null,
  hbFit: false,
  inventoryType: ''
};

const reducer = (
  state: ProductStateType = initialState,
  action: ProductActionType
) => {
  switch (action.type) {
    case 'PRODUCT/FETCH_DETAILS':
      return {
        ...state,
        loadingProduct: true,
        noProductFound: false
      };

    case 'PRODUCT/FETCH_DETAILS_SUCCESS':
      return {
        ...state,
        ...action.payload.data,
        loadingProduct: false,
        noProductFound: false
      };
    case 'PRODUCT/FETCH_DETAILS_FAILURE':
      return {
        ...state,
        loadingProduct: false,
        noProductFound: true
      };
    case 'PRODUCT/FETCH_REVIEW_COUNT_SUCCESS':
      return {
        ...state,
        reviewCount: action.payload.count
      };
    case 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS':
      return {
        ...state,
        recentlyViewedProductsLoading: true
      };
    case 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS_SUCCESS':
      return {
        ...state,
        recentlyViewedProductsNew: action.payload.products,
        recentlyViewedProductsLoading: false
      };
    case 'PRODUCT/FETCH_RECENTLY_VIEWED_PRODUCTS_FAILURE':
      return {
        ...state,
        recentlyViewedProductsLoading: false
      };
    case 'PRODUCT/ADD_TO_RECENTLY_VIEWED_NEW_SUCCESS':
      return {
        ...state,
        recentlyViewedProductsNew: action.payload.products,
        recentlyViewedProductsLoading: false
      };
    case 'PRODUCT/ADD_TO_RECENTLY_VIEWED_NEW_FAILURE':
      return {
        ...state,
        recentlyViewedProductsNew: [],
        recentlyViewedProductsLoading: false
      };
    case 'PRODUCT/SET_LOADING_STATE':
      return {
        ...state,
        loadingProduct: action.loadingState
      };
    case 'PRODUCT/SET_SIZE_GUIDE_OPEN':
      return {
        ...state,
        sizeGuideOpen: action.isOpen
      };
    case 'PRODUCT/FETCH_RELATED_PRODUCTS':
      return {
        ...state,
        relatedProducts: [],
        relatedProductsLoading: true
      };
    case 'PRODUCT/FETCH_RELATED_PRODUCTS_FAILURE':
      return {
        ...state,
        relatedProducts: [],
        relatedProductsLoading: false
      };
    case 'PRODUCT/FETCH_RELATED_PRODUCTS_SUCCESS':
      return {
        ...state,
        relatedProducts: action.payload.products,
        relatedProductsLoading: false
      };
  }
  return state;
};

export default reducer;
