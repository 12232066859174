import { SessionActions, SessionState } from './types';

const initialState = {
  csrfToken: '',
  messages: [],
  signedIn: false,
  purchasesCount: 0,
  config: {
    yotpoAppKey: null,
    showYotpoWriteButton: false,
    reviewThreshold: 5
  },
  cart: {
    productsCount: 0,
    orderNumber: null,
    token: null
  },
  justLandedProductCount: null,
  signedInUser: {
    email: null,
    hasToolbar: false,
    isAdmin: false,
    isSuperAdmin: false,
    viewedFeatures: [],
    phoneNumber: '',
    createdAt: '',
    showAdminRevenueOpportunity: false,
    showDropshipProducts: false
  },
  showHardGate: null,
  gate: {
    params: {},
    paths: []
  },
  loadedAt: null,
  sessionId: null,
  fetching: false
};

const reducer = (
  state: SessionState = initialState,
  action: SessionActions
) => {
  switch (action.type) {
    case 'SESSION/SETUP_COMPLETE':
      return {
        ...state,
        showHardGate: action.showHardGate,
        fetching: false
      };
    case 'SESSION/SETUP_MESSAGES':
      return {
        ...state,
        messages: action.messages
      };
    case 'SESSION/FETCH':
      return {
        ...state,
        fetching: true,
        config: {
          ...state.config,
          applePayEnabled: Boolean(
            process.env.NODE_ENV === 'production' &&
              window?.ApplePaySession &&
              window.ApplePaySession?.canMakePayments()
          )
        }
      };
    case 'SESSION/FETCH_SESSION_COMPLETE':
      if (action.success) {
        return {
          ...state,
          ...action.data,
          loadedAt: new Date().valueOf(),
          fetching: false
        };
      }

      return state;
    case 'SESSION/LOG_OUT_SUCCESS':
      return {
        ...state,
        signedIn: false,
        signedInUser: { ...initialState.signedInUser },
        cart: { ...initialState.cart }
      };
    case 'SESSION/SET_SESSION_ID':
      return {
        ...state,
        sessionId: action.sessionId
      };
    case 'SESSION/SET_PHONE_NUMBER':
      return {
        ...state,
        signedInUser: { ...state.signedInUser, phoneNumber: action.phoneNumber }
      };
    case 'SESSION/ADD_VIEWED_FEATURE_SUCCESS':
      return {
        ...state,
        signedInUser: {
          ...state.signedInUser,
          viewedFeatures: [
            ...state.signedInUser.viewedFeatures,
            action.viewedFeature
          ]
        }
      };
    case 'SESSION/TOGGLE_ADMIN_REVENUE_TOOLBAR':
      return {
        ...state,
        signedInUser: {
          ...state.signedInUser,
          showAdminRevenueOpportunity: !state.signedInUser
            .showAdminRevenueOpportunity
        }
      };
    case 'SESSION/TOGGLE_DROPSHIP_TOOLBAR':
      return {
        ...state,
        signedInUser: {
          ...state.signedInUser,
          showDropshipProducts: !state.signedInUser.showDropshipProducts
        }
      };
    default:
      // The first time our preloaded state is set, we need to merge in our
      // initial data
      if (typeof state.loadedAt === 'undefined') {
        return {
          ...initialState,
          ...state
        };
      }

      return state;
  }
};

export default reducer;
