import reducer from './reducer';
import * as actionCreators from './action-creators';

const {
  fetchProductDetails,
  fetchReviewCount,
  setProductLoading,
  setSizeGuideOpen,
  fetchRelatedProducts,
  addToRecentlyViewedNew,
  fetchRecentlyViewedProducts
} = actionCreators;

export {
  fetchProductDetails,
  fetchReviewCount,
  setProductLoading,
  setSizeGuideOpen,
  fetchRelatedProducts,
  addToRecentlyViewedNew,
  fetchRecentlyViewedProducts
};

export default reducer;
