// @flow

import type { AnalyticsProduct } from 'types';
import type { JournalCategorySegmentData, PageLoadedData } from './types';
import type { JournalPostSegmentData } from 'modules/journal-post/types';

import events from './events';
import selectors from './selectors';
import actionCreators from './action-creators';

export type AnalyticsState = {
  listName: ?string,
  listCategory: ?string
};

export { events };

export { selectors };

export { actionCreators };

export function analyticsLoaded(): boolean {
  return typeof window !== 'undefined' && window.rudderanalyticsLoaded;
}

export function recordImpression(product: AnalyticsProduct, position: number) {
  return {
    type: 'ANALYTICS/IMPRESSION',
    product,
    position
  };
}

export function trackLink(
  eventName: string,
  payload: {
    // TODO: Add `...` when upgrading to Flow w/ default exact types.
  }
): void {
  if (typeof window === 'undefined' || !window.rudderanalyticsLoaded) {
    return;
  }

  window.rudderanalytics.track(eventName, payload, {
    page: {
      path: window.location.pathname,
      url: window.location.href
    }
  });
}

// By default, title, url, path, and referrer are automatically
// added to each page call.
export function trackPageView(eventData?: PageLoadedData): void {
  if (
    typeof window === 'undefined' ||
    typeof window.rudderanalytics === 'undefined'
  ) {
    return;
  }

  if (eventData) {
    window.rudderanalytics.page(eventData);
    return;
  }

  window.rudderanalytics.page();
}

export function trackAction(
  eventName: string,
  payload: JournalPostSegmentData | JournalCategorySegmentData | {}
): void {
  if (
    typeof window === 'undefined' ||
    typeof window.rudderanalytics === 'undefined'
  ) {
    return;
  }

  window.rudderanalytics.track(eventName, payload);
}

export function gtmTrack(event: string): void {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event
  });
}

const reducer = (state: Object = {}, action: { type: string }) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
