import { BundlesStateType, BundlesActionType } from './types';

const initialState: BundlesStateType = {
  bundlesData: {},
  selectedProductIdForBundle: null,
  error: false,
  loading: false,
  applicableProducts: []
};

const reducer = (
  state: BundlesStateType = initialState,
  action: BundlesActionType
) => {
  switch (action.type) {
    case 'BUNDLES/GET':
      return { ...state, loading: true };
    case 'BUNDLES/GET_SUCCESS':
      return {
        ...state,
        bundlesData: { ...state.bundlesData, ...action.bundlesData },
        error: false,
        loading: false
      };
    case 'BUNDLES/GET_FAILURE':
      return {
        ...state,
        error: true,
        loading: false
      };
    case 'BUNDLES/SET_ACTIVE_BUNDLE':
      return {
        ...state,
        error: false,
        loading: true
      };
    case 'BUNDLES/SET_ACTIVE_BUNDLE_SUCCESS':
      return {
        ...state,
        selectedProductIdForBundle: action.productId,
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
