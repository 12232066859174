// @flow

import './AuthModal.scss';

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import SoftGate from 'components/SoftGate/SoftGate';

import useSoftGate from './useSoftGate';

import useQuery from 'hooks/useQuery';

import { trackAction, events } from 'modules/analytics';
import { newsletterOptin } from 'modules/auth-modal';

import type { AuthDomain } from 'modules/auth-modal/types';

const AuthModal = () => {
  const queryParams = useQuery();
  const dispatch = useDispatch();

  const authModal = useSelector((state) => state.authModal);
  const isMobile = useSelector((state) => state.ui.browserWidth <= 1000);

  const { pathname } = useSelector((state) => state.router.location);
  const suppressedRoute = ['/early-access', '/explorers-grants'].includes(
    pathname
  );

  const [modalType, setModalType] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [modalDomain, setModalDomain] = React.useState<AuthDomain | null>(null);

  const massagedModalDomain = useSoftGate(
    authModal.force,
    authModal.authDomain
  );

  React.useEffect(() => {
    const email = queryParams.get('kemail');
    const utmMedium = queryParams.get('utm_medium');

    if (utmMedium === 'email' && !isEmpty(email)) {
      dispatch(newsletterOptin(email));
    }
  }, [dispatch, queryParams]);

  React.useEffect(() => {
    const isPortraitView = ['favorites', 'back-in-stock'].includes(
      massagedModalDomain
    );

    const isFullAuthView = ['plain', 'email-only', 'big-event-coming'].includes(
      massagedModalDomain
    );

    const isShortView = [
      'journal',
      'promotion',
      'exit-intent',
      'pappy-day',
      'sitewide-sale',
      'cyber-week'
    ].includes(massagedModalDomain);

    if (isPortraitView) {
      setModalType('portrait');
    } else if (isShortView) {
      setModalType('short');
    } else if (isFullAuthView) {
      setModalType('auth');
    }

    if (showModal) {
      setModalDomain(massagedModalDomain);
    } else {
      setModalDomain(null);
      setModalType(null);
    }
  }, [authModal.modalDomain, authModal.view, massagedModalDomain, showModal]);

  const isInitialLoad = authModal.view === 'initial';

  const shouldDelayOpen = React.useMemo(() => {
    const isCampaign = /utm_content|utm_campaign|utm_medium/.test(
      window.location.search
    );

    return isMobile && isInitialLoad && !isCampaign;
  }, [isMobile, isInitialLoad]);

  React.useEffect(() => {
    // initial Softgate trigger logic
    if (authModal.open && isInitialLoad && !showModal) {
      // Delay open for Mobile and Campaigns
      if (shouldDelayOpen) {
        setTimeout(() => {
          setShowModal(true);
        }, 7000);
      } else {
        setShowModal(true);
      }

      trackAction(events.SOFT_GATE_VIEWED, {
        label: authModal.view
      });

      return;
    }

    // If user clicks to open
    if (authModal.open && !showModal && !isInitialLoad) {
      setShowModal(true);
    }
  }, [authModal, shouldDelayOpen, showModal, isInitialLoad, modalType]);

  React.useEffect(() => {
    if (showModal && !authModal.open) {
      setShowModal(false);
    }
  }, [authModal, showModal]);

  if (
    !suppressedRoute &&
    showModal &&
    authModal.open &&
    modalDomain &&
    modalType
  ) {
    return (
      <SoftGate
        modalType={modalType}
        modalDomain={modalDomain}
        view={authModal.view}
      />
    );
  }

  return null;
};

export { AuthModal };
export default AuthModal;
