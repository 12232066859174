import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import Cookies from 'js-cookie';
import some from 'lodash/some';
import qs from 'qs';
import AlgoliaInsights from 'search-insights';

import { open as openAuthModal } from 'modules/auth-modal';
import { fetchNotifications } from 'modules/notifications';
import { fetchFavoritesHash } from 'modules/user-favorites';
import { initializeEppo } from 'modules/experiments/action-creators';
import { fetchCurrent as fetchCurrentCart } from 'modules/cart';

import * as actions from './index';
import * as requests from './requests';
import { SessionAddViewedFeaturesType } from './types';
import { ApplicationState } from 'types/rootState';

function* sessionSaga(): SagaIterator {
  yield takeEvery('SESSION/SETUP', setupSession);
  yield takeEvery('SESSION/FETCH', fetchSession);
  yield takeEvery('SESSION/LOG_OUT', getLogOutSaga);
  yield takeEvery('SESSION/ADD_VIEWED_FEATURE', addViewedFeatureSaga);
}

function* setupSession(): SagaIterator {
  if (window?.HB?.__PRELOADED_MESSAGES__.length) {
    yield put(actions.setupMessages(window.HB.__PRELOADED_MESSAGES__));
  }
  const { algoliaAppId, algoliaClientKey } = yield select(
    (state: ApplicationState) => state.session.config
  );

  const { signedIn, gate, config } = yield select(
    (state: ApplicationState) => state.session
  );

  AlgoliaInsights('init', {
    appId: algoliaAppId,
    apiKey: algoliaClientKey
  });

  const [locationChangeAction] = yield all([
    take(LOCATION_CHANGE),
    take('SESSION/FETCH_SESSION_COMPLETE')
  ]);

  const { isFirstRendering, location } = locationChangeAction.payload;
  const query = qs.parse(location.search.replace('?', ''));

  let hasLoggedIn = Cookies.get('has_logged_in_before') === 'true';
  if (signedIn) {
    hasLoggedIn = true;
    Cookies.set('has_logged_in_before', 'true');
  }

  let showHardGate = false;
  let showSoftGate = false;
  let gateSuppressed = Cookies.get('suppress_gate_for_visit') === 'true';

  const suppressionParamsMatch = some(gate.params, (param) => {
    return query[param.key] === param.value;
  });

  const suppressionPathMatch = some(
    gate.paths,
    (path) => location.pathname === path
  );

  if (
    !gateSuppressed &&
    (hasLoggedIn || suppressionParamsMatch || suppressionPathMatch)
  ) {
    gateSuppressed = true;
  }

  const googleOrganic =
    document.referrer === 'https://www.google.com/' &&
    query.gclid == null &&
    query.utm_medium !== 'googlep';

  if (isFirstRendering) {
    const hasVisitedBefore = Cookies.get('has_visited_before') === 'true';

    if (location.pathname === '/') {
      const hardGateDisabled = config.hardGateDisabled;

      showHardGate =
        !hardGateDisabled &&
        !signedIn &&
        !hasVisitedBefore &&
        !hasLoggedIn &&
        !gateSuppressed &&
        !googleOrganic;
    }

    if (!showHardGate) {
      const softGateDisabled = config.softGateDisabled;
      showSoftGate = !signedIn && !gateSuppressed && !softGateDisabled;
    }
  }

  if (!showHardGate) {
    Cookies.set('has_visited_before', 'true', { expires: 365 });

    if (!gateSuppressed) {
      Cookies.set('suppress_gate_for_visit', 'true', { expires: 1 / 48 });
    }
  }

  // for enhancing user identification of AppLovin pixel
  // docs - https://developers.applovin.com/en/audience-plus/google-tag-manager#enhanced-user-identification-recommended
  const appLovinCookie = Cookies.get('_axwrt');

  if (appLovinCookie) {
    Cookies.set('axwrt', appLovinCookie, { expires: 365 });
  }

  if (showSoftGate) {
    yield put(openAuthModal('initial', 'promotion'));
  }
  yield put(actions.setupSessionComplete(showHardGate));
  yield put(initializeEppo());
}

function* fetchSession(): SagaIterator {
  const { signedIn, loadedAt, fetching } = yield select(
    (state: ApplicationState) => state.session
  );
  // modules/analytics/saga also can fetch the session if it needs it,
  // so we need to check if it's already been loaded or currently being fetched.
  if (!loadedAt && !fetching) {
    try {
      const response = yield call(requests.getSession);

      yield put(actions.fetchSessionComplete(true, response.data));

      if (signedIn) {
        yield put(fetchNotifications());
        yield put(fetchFavoritesHash());
      }
    } catch {
      yield put(actions.fetchSessionComplete(false));
    }
  }
}

function* getLogOutSaga() {
  try {
    yield call(requests.logOut);
    yield put(actions.getLogOutSuccess());
    yield put(
      actions.setupMessages([
        {
          type: 'default',
          content: 'You have been logged out successfully. See You Out There!'
        }
      ])
    );
    yield put(fetchCurrentCart());
  } catch {
    yield put(actions.getLogOutFailure());
  }
}

function* addViewedFeatureSaga(action: SessionAddViewedFeaturesType) {
  try {
    yield call(requests.addViewedFeature, action.viewedFeature);

    yield put(actions.addViewedFeatureSuccess(action.viewedFeature));
  } catch {
    // nothing
  }
}

export default sessionSaga;
